const checkFile = (fileNames) => {
  fileNames.forEach(fileName => {
    Object.defineProperty(window, '__FILES_LOADED__', {
      value: {
        [fileName]: true
      }
    })
  })
}

checkFile(['application.js'])
